export default {
  async iniciarTrabajoParte (Vue, formData, idparteTrabajo) {
    await Vue.$offline.tiempoTrabajado.iniciarTrabajoParte(
      formData.para,
      idparteTrabajo,
      formData.latitud,
      formData.longitud,
      formData.es_desplazamiento
    )
  }
}
